<template>
  <v-container fluid>
    <v-container>
      <div class="d-md-flex justify-space-between">
        <div>
          <h1>Bienvenu !</h1>
        </div>

        <div>
          <v-btn
            color="primary"
            dark
            class="mb-2 mr-8"
            @click="addItem"
            v-bind="attrs"
            outlined
            pill
            v-on="on"
            rounded
          >
            <v-icon left> mdi-account-outline </v-icon>
            Ajouter un(e) étudiant(e)
          </v-btn>
        </div>
      </div>
    </v-container>

    <v-dialog v-model="editorDialog" max-width="1000px" class="black">
      <editor-x
        :fields="fields"
        :model="Model"
        :callback="callBack"
        title="Enregistrer un(e) étudiant(e)"
        @success="successEditor"
      />
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="1000px" class="black">
      <delete-dialog
        :id="Model && Model._id ? Model._id : ''"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "@/utils/data";
import { addStudent, updateFaculty, deleteFaculty } from "@/api/user";
import EditorX from "@/components/universal/EditorX.vue";
import DeleteDialog from "@/components/universal/DeleteDialog.vue";
import { getLevels } from "@/api/universal";
import { functions } from "@/utils/constant";

export default {
  name: "Students",
  components: {
    EditorX,
    DeleteDialog,
  },
  data: () => ({
    callBack: "",
    searchData: "",
    dialog: false,
    loading: false,
    headers: [
      {
        text: "Nom",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Acronyme",
        sortable: false,
        value: "acronym",
      },
      {
        text: "Description",
        sortable: false,
        value: "description",
      },
      {
        text: "Actions",
        width: "150px",
        align: "left",
        value: "actions",
        sortable: false,
      },
    ],
    editedIndex: -1,
    Model: {},
    defaultItem: {},
    editorDialog: false,
    deleteDialog: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters([
      "Students",
      "Schools",
      "UserProfile",
      "Departments",
      "Godfathers",
      "Options",
    ]),
    fields() {
      return [
        {
          name: "lastName",
          label: "Nom",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "firstName",
          label: "Prénoms",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "birthday",
          label: "Date de naissance",
          type: "Date",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "birthPlace",
          label: "Lieu de naissance",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "sex",
          label: "Sexe",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: ["M", "F"],
          },
        },
        
        {
          name: "country",
          label: "Pays de naissance",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: functions.getCountries(),
          },
          default:"Bénin"
        },
        {
          name: "city",
          label: "Ville de naissance",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "nationality",
          label: "Nationalité",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "address",
          label: "Adresse",
          type: "String",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "phone",
          label: "Numéro de téléphone",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "email",
          label: "Email",
          type: "String",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "NIP",
          label: "Numéro d'identification personnel",
          type: "String",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "IDNumber",
          label: "Numéro piéce d'identité",
          type: "String",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "currentLevel",
          label: "Niveau",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: getLevels(),
          },
        },

        {
          name: "scholarship",
          label: "Numéro d'attestation de bourse",
          icon: "mdi-key",
          type: "String",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          boolShowingCondition:{
            default:false,
            trueText:"Oui",
            falseText:"Non",
            label:"Boursier ?"
          }
        },
        //   {
        //   name: "department",
        //   label: "Mention",
        //   type: "Select",
        //   col: 12,
        //   isRequiredForUpdate: true,
        //   isRequiredForAdd: true,
        //   existForUpdate: true,
        //   existForAdd: true,
        //   select: {
        //     store: {
        //       action: this.getDepartments,
        //       params: { faculty: "" },
        //       getter: this.Departments,
        //       fieldName: "name",
        //     },
        //   },
        // },
        {
          name: "option",
          label: "Option",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            store: {
              action: this.getOptions,
              params: { department: "" },
              getter: this.Options,
              fieldName: "name",
            },
          },
        },
        {
          name: "schoolYear",
          label: "",
          type: "Static",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value:
            this.UserProfile.school &&
            this.UserProfile.school.currentYear &&
            this.UserProfile.school.currentYear._id
              ? this.UserProfile.school.currentYear._id
              : this.UserProfile.school.currentYear,
        },
        {
          name: "school",
          label: "",
          type: "Static",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value:
            this.UserProfile.school && this.UserProfile.school._id
              ? this.UserProfile.school._id
              : this.UserProfile.school,
        },

        
      ];
    },
  },

  watch: {},
  methods: {
    ...mapActions([
      "getStudents",
      "getSchools",
      "getDepartments",
      "getGodfathers",
      "getOptions",
    ]),
    closeForm() {
      this.Model = {};
      this.editorDialog = false;
      this.editedIndex = -1;
    },

    async init() {
      this.loading = true;
      try {
        await this.getStudents();
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("les années")
        );
      }
      this.loading = false;
    },
    successEditor() {
      this.editorDialog = false;
      this.deleteDialog = false;
      this.init();
    },
    addItem() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addStudent;
      this.editorDialog = true;
    },
    updateItem(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.editorDialog = true;
      this.callBack = updateFaculty;
    },

    deleteItem(item) {
      this.deleteDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteFaculty;
    },
  },
};
</script>

<style >
</style>
